import {
  CREATE_SMS_REQUEST,
  CREATE_SMS_RESOLVE,
  CREATE_SMS_REJECT,
  FETCH_SMS_REQUEST,
  FETCH_SMS_RESOLVE,
  FETCH_SMS_REJECT,
} from '../actions/sms';

export default function sms(
  state = {
    inbox: [],
    isFetching: false,
  },
  action,
) {
  switch (action.type) {
    case CREATE_SMS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_SMS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        message: 'SMS created successfully',
      };
    case CREATE_SMS_REJECT:
      return {
        ...state,
        isFetching: false,
        message: action.payload[0].message,
      };
    case FETCH_SMS_REQUEST:
      return {
        ...state,
        isFetching: true,
        inbox: [],
      };
    case FETCH_SMS_RESOLVE:
      return {
        ...state,
        isFetching: false,
        inbox: action.payload.smsInbox,
      };
    case FETCH_SMS_REJECT:
      return {
        ...state,
        isFetching: false,
        SMS: [],
        message: action.payload[0].message,
      };
    default:
      return state;
  }
}
