
import { connect } from 'react-redux';
import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import {
  Navbar,
  Nav,
  NavItem,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';

import Icon from '../Icon';

import { logoutUser, fetchMe } from '../../actions/user';
import s from './Header.scss';

class Header extends React.Component {
  static propTypes = {
    user: PropTypes.shape(),
    sidebarToggle: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: null,
    sidebarToggle: () => {},
  };

  state = { isOpen: false };

  componentDidMount() {
    this.props.dispatch(fetchMe());
  }

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  doLogout = () => {
    this.props.dispatch(logoutUser());
  }

  render() {
    const {isOpen} = this.state;
    const { user } = this.props;
    return (
      <Navbar className={s.root}>
        <Nav>
          <NavItem
            className={cx('visible-xs mr-4 d-sm-up-none', s.headerIcon, s.sidebarToggler)}
            href="#"
            onClick={this.props.sidebarToggle}
          >
            <i className="fa fa-bars fa-2x text-muted" />
          </NavItem>
          {['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPERADMIN'].includes(user && user.role) &&
            <NavItem>
              <InputGroup>
                <Input placeholder="Search for..." />
                <InputGroupAddon addonType="append" className="px-2">
                  <i className="fa fa-search" />
                </InputGroupAddon>
              </InputGroup>
            </NavItem>
          }
        </Nav>
        <Nav className="ml-auto">
          {['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPERADMIN'].includes(user && user.role) &&
            <React.Fragment>
              <NavItem className={cx('', s.headerIcon)}>
                <NavLink to="/app/sms">
                  <Button>
                    <Icon glyph="mail"/>
                    <span>1</span>
                  </Button>
                </NavLink>
              </NavItem>
              <NavItem className={cx('', s.headerIcon)}>
                <Button>
                  <Icon glyph="notification"/>
                  {/* <span>13</span> */}
                </Button>
              </NavItem>
              <NavItem className={cx('', s.headerIcon)}>
                <Button>
                  <Icon glyph="settings"/>
                </Button>
              </NavItem>
            </React.Fragment>
          }
          <Dropdown isOpen={isOpen} toggle={this.toggleDropdown}>
            <DropdownToggle nav>
              <img className={cx('rounded-circle mr-sm', s.adminPhoto)} src={ user && user.profile.picture } alt="administrator" />
              <span className="text-body">{ user && user.profile.displayName }</span>
              <i className={cx('fa fa-angle-down ml-sm', s.arrow, {[s.arrowActive]: isOpen})} />
            </DropdownToggle>
            <DropdownMenu style={{width: '100%'}}>
              {/* {['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPERADMIN'].includes(user && user.role) &&
                <React.Fragment>
                  <DropdownItem>
                    <NavLink to="/app/posts">Posts</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink to="/app/profile">Profile</NavLink>
                  </DropdownItem>
                </React.Fragment>
              } */}
              <DropdownItem onClick={this.doLogout}>
                Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    init: state.runtime.initialNow,
    user: state.auth.user,
  };
}
export default connect(mapStateToProps)(withStyles(s)(Header));
