
import React from 'react';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Switch, Route, withRouter } from 'react-router';
import { SecureRoute, ROLES_EMPLOYEES, ROLES_ADMINS } from '../Secure/Route';

// an example of react-router code-splitting
/* eslint-disable */
import loadUsage from 'bundle-loader?lazy!../../pages/usage';
import loadStatistics from 'bundle-loader?lazy!../../pages/statistics';
import loadUsers from 'bundle-loader?lazy!../../pages/users';
import loadSessions from 'bundle-loader?lazy!../../pages/sessions';
import loadPosts from 'bundle-loader?lazy!../../pages/posts';
import loadSms from 'bundle-loader?lazy!../../pages/sms';
// import loadPrivacy from 'bundle-loader?lazy!../../pages/privacy';
import loadProfile from 'bundle-loader?lazy!../../pages/profile';

import loadGizmoNews from 'bundle-loader?lazy!../../pages/gizmo/news';
import loadGizmoUserSessions from 'bundle-loader?lazy!../../pages/gizmo/userSessions';
import loadGizmoUsers from 'bundle-loader?lazy!../../pages/gizmo/users';
import loadGizmoPoints from 'bundle-loader?lazy!../../pages/gizmo/points';
import loadGizmoUserStats from 'bundle-loader?lazy!../../pages/gizmo/userStats';

import loadDashboard from 'bundle-loader?lazy!../../pages/examples/dashboard';
import loadTypography from 'bundle-loader?lazy!../../pages/examples/typography';
import loadTables from 'bundle-loader?lazy!../../pages/examples/tables';
import loadButtons from 'bundle-loader?lazy!../../pages/examples/buttons';
import loadNotifications from 'bundle-loader?lazy!../../pages/examples/notifications';
import loadCharts from 'bundle-loader?lazy!../../pages/examples/charts';
import loadIcons from 'bundle-loader?lazy!../../pages/examples/icons';
import loadMaps from 'bundle-loader?lazy!../../pages/examples/google';
import loadNotFound from 'bundle-loader?lazy!../../pages/notFound';
/* eslint-enable */

import s from './Layout.scss';
import Header from '../Header';
import Footer from '../Footer';
import Bundle from '../../core/Bundle';
import Sidebar from '../Sidebar';

// Dashboard component is loaded directly as an example of server side rendering
import Dashboard from '../../pages/dashboard/Dashboard';
import Activate from '../../pages/activate/Activate';

const UsageBundle = Bundle.generateBundle(loadUsage);
const StatisticsBundle = Bundle.generateBundle(loadStatistics);
const UsersBundle = Bundle.generateBundle(loadUsers);
const SessionsBundle = Bundle.generateBundle(loadSessions);
const PostsBundle = Bundle.generateBundle(loadPosts);
const SmsBundle = Bundle.generateBundle(loadSms);
// const PrivacyBundle = Bundle.generateBundle(loadPrivacy);
const ProfileBundle = Bundle.generateBundle(loadProfile);

const GizmoNewsBundle = Bundle.generateBundle(loadGizmoNews);
const GizmoUserSessionsBundle = Bundle.generateBundle(loadGizmoUserSessions);
const GizmoUsersBundle = Bundle.generateBundle(loadGizmoUsers);
const GizmoPointsBundle = Bundle.generateBundle(loadGizmoPoints);
const GizmoUserStatsBundle = Bundle.generateBundle(loadGizmoUserStats);

const DashboardBundle = Bundle.generateBundle(loadDashboard);
const TypographyBundle = Bundle.generateBundle(loadTypography);
const TablesBundle = Bundle.generateBundle(loadTables);
const ButtonsBundle = Bundle.generateBundle(loadButtons);
const NotificationsBundle = Bundle.generateBundle(loadNotifications);
const ChartsBundle = Bundle.generateBundle(loadCharts);
const IconsBundle = Bundle.generateBundle(loadIcons);
const MapsBundle = Bundle.generateBundle(loadMaps);
const NotFoundBundle = Bundle.generateBundle(loadNotFound);

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
    };
  }

  render() {
    return (
      <div className={s.root}>
        <Sidebar />
        <div
          className={cx(s.wrap, {[s.sidebarOpen]: this.state.sidebarOpen})}
        >
          <Header
            sidebarToggle={() =>
              this.setState({
                sidebarOpen: !this.state.sidebarOpen,
              })
            }
          />
          <main className={s.content}>
            <Switch>
              <Route path="/app/activate" exact component={Activate} />
              <SecureRoute roles={ROLES_EMPLOYEES} path="/app" exact component={Dashboard} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/usage" component={UsageBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/statistics" component={StatisticsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/users" component={UsersBundle} />
              <SecureRoute roles={ROLES_EMPLOYEES} path="/app/sessions" component={SessionsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/posts" component={PostsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/sms" component={SmsBundle} />
              {/* <Route path="/app/privacy" exact component={PrivacyBundle} /> */}
              <SecureRoute roles={ROLES_EMPLOYEES} path="/app/profile" exact component={ProfileBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/gizmo/news" exact component={GizmoNewsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/gizmo/user-sessions" exact component={GizmoUserSessionsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/gizmo/users" exact component={GizmoUsersBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/gizmo/points" exact component={GizmoPointsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/gizmo/user-stats" exact component={GizmoUserStatsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/dashboard" exact component={DashboardBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/typography" exact component={TypographyBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/tables" exact component={TablesBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/notifications" exact component={NotificationsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/buttons" exact component={ButtonsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/charts" exact component={ChartsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/icons" exact component={IconsBundle} />
              <SecureRoute roles={ROLES_ADMINS} path="/app/components/maps" exact component={MapsBundle} />
              <Route component={NotFoundBundle} />
            </Switch>
          </main>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(Layout));
