import { request } from './helpers';


export const CREATE_SMS = 'CREATE_SMS';
export const CREATE_SMS_REQUEST = 'CREATE_SMS_REQUEST';
export const CREATE_SMS_RESOLVE = 'CREATE_SMS_RESOLVE';
export const CREATE_SMS_REJECT = 'CREATE_SMS_REJECT';

export function createPost(postData) {
  return request({
    type: CREATE_SMS,
    query: `mutation CreateSms($input: SmsInput!) {
      addSms(input: $input){
        id,
        number,
        messagee
      }
    }`,
    variables: {
      input: postData,
    }
  });
}


export const FETCH_SMS = 'FETCH_SMS';
export const FETCH_SMS_REQUEST = 'FETCH_SMS_REQUEST';
export const FETCH_SMS_RESOLVE = 'FETCH_SMS_RESOLVE';
export const FETCH_SMS_REJECT = 'FETCH_SMS_REJECT';

export function fetchSmsInbox() {
  return request({
    type: FETCH_SMS,
    query: `{
      smsInbox {
        id
        number
        message
        receivedTime
        status
      }
    }`,
  });
}
